import React from 'react';
import { Icon } from '@just-ai/just-ui/dist/Icon';
import styles from './styles.module.scss';
import { t } from 'localization';

type CardItemFooterProps = {
  owner?: string;
  fittable?: boolean;
};

const CardItemFooter = ({ owner, fittable = false }: CardItemFooterProps) => {
  return (
    <div className={styles.footer}>
      {owner && (
        <div className={styles.footer__item}>
          <Icon name='farCheckCircle' />
          <span>{owner}</span>
        </div>
      )}
      {fittable && (
        <div className={styles.footer__item}>
          <Icon name='farUserGraduate' />
          <span>{t('ServicesCatalog:CardItemFooter:Fittable')}</span>
        </div>
      )}
    </div>
  );
};

export default React.memo(CardItemFooter);
