import React, { useCallback } from 'react';
import { Control, useController } from 'react-hook-form';
import cn from 'classnames';

import { Icon } from '@just-ai/just-ui/dist/Icon';
import { FileUpload, FileUploadProps } from '@just-ai/just-ui/dist/FileUpload';

import { t } from 'localization';
import styles from './styles.module.scss';
import IconButton from 'components/IconButton';

interface ControlledFileUploadInterface extends FileUploadProps {
  name: string;
  className?: string;
  control: Control<any>;
  onChange?: () => void;
  subText?: string;
}

const ControlledFileUpload = ({
  className,
  id,
  name,
  control,
  onChange,
  subText,
  ...props
}: ControlledFileUploadInterface) => {
  const {
    field: { value, onChange: handleChange },
    fieldState: { error },
  } = useController({ name, control, defaultValue: '' });

  const handleUpload = useCallback(
    (files: FileList) => {
      handleChange(files[0]);
      onChange && onChange();
    },
    [handleChange, onChange]
  );

  const handleReset = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      handleChange(undefined);
      event.preventDefault();
      event.stopPropagation();
    },
    [handleChange]
  );

  return (
    <>
      <div className={styles.controlledFileUpload__row}>
        <FileUpload
          id={id}
          className={cn(className, styles.controlledFileUpload, error && styles.controlledFileUpload__error)}
          loadingType='progressBar'
          onChange={handleUpload}
          {...props}
        >
          {value ? (
            <div className={styles.controlledFileUpload__fileInfo}>
              <div className={styles.controlledFileUpload__fileStatus}>
                <Icon name='farCheckCircle' color='success' size='lg' />
                <div className={styles.controlledFileUpload__fileTitle}>
                  <span>{t('FileUpload:FileUploaded')}</span>
                  <h3>{value?.name}</h3>
                </div>
              </div>
              <IconButton
                className={styles.controlledFileUpload__delete}
                data-test-id='FileUpload:Delete'
                name='farTrashAlt'
                type='button'
                onClick={handleReset}
              />
            </div>
          ) : (
            <div className={styles.controlledFileUpload__textWrap}>
              <p className={styles.controlledFileUpload__text}>
                <span className={styles.controlledFileUpload__pseudoLink} onClick={e => e.preventDefault()}>
                  {t('FileUpload:AttachFile')}{' '}
                </span>
                {t('FileUpload:OrDropItHere')}
              </p>
              <p className={styles.controlledFileUpload__subText}>{subText ? subText : null}</p>
            </div>
          )}
        </FileUpload>
        {value && (
          <div>
            <Icon name='faCheckCircle' color='primary' size='lg' />
          </div>
        )}
      </div>
      {error && <span className={styles.controlledFileUpload__errorMessage}>{error.message}</span>}
    </>
  );
};

export default React.memo(ControlledFileUpload);
