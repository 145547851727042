import { Container, Token } from 'typedi';
import { Configuration } from 'api/CailaAdminApi/api/client';

export const tokens = {
  TEMP_REGISTRATION_LINK: new Token<string>('TEMP_REGISTRATION_LINK'),
  SUPPORT_EMAIL: new Token<string>('SUPPORT_EMAIL'),
  SLACK_COMMUNITY: new Token<string>('SLACK_COMMUNITY'),
  REQUEST_MANAGER_CONFIGURATION: new Token<Configuration>('TEMP_REGISTRATION_LINK'),
  REQUEST_MANAGER_BASE_PATH: new Token<string>('REQUEST_MANAGER_BASE_PATH'),
  PRIVACY_POLICY_LINK: new Token<string>('PRIVACY_POLICY_LINK'),
  USE_AGREEMENT_LINK: new Token<string>('USE_AGREEMENT_LINK'),
  BANNER_MOUNT_ID: new Token<string>('BANNER_MOUNT_ID'),
};

Container.set([
  { id: tokens.TEMP_REGISTRATION_LINK, value: 'http://promo-ru.caila.io' },
  { id: tokens.SUPPORT_EMAIL, value: 'support@caila.io' },
  { id: tokens.PRIVACY_POLICY_LINK, value: 'https://app.jaicp.com/documents/privacy-policy-ru/' },
  { id: tokens.USE_AGREEMENT_LINK, value: 'https://app.jaicp.com/documents/terms-of-service-ru/' },
  {
    id: tokens.SLACK_COMMUNITY,
    value: 'https://join.slack.com/t/justaicommunity/shared_invite/zt-xeob7kus-3jXPDLmBMOPnWnQniDFafw',
  },
  {
    id: tokens.REQUEST_MANAGER_CONFIGURATION,
    value: {
      baseOptions: { timeout: 30000 },
    },
  },
  { id: tokens.REQUEST_MANAGER_BASE_PATH, value: '' },
  { id: tokens.BANNER_MOUNT_ID, value: 'banner-root' },
]);
