import React, { useRef, useState } from 'react';
import cn from 'classnames';
import { useInterval } from 'utils/hooks';
import styles from './styles.module.scss';

import { t } from 'localization';

interface ProgressBarInterface {
  className?: string;
}

const ProgressBar = ({ className }: ProgressBarInterface) => {
  const [progress, setProgress] = useState<number>(0);
  const stepRef = useRef<number>(50);
  const intervalRef = useInterval(() => {
    if (progress >= 100) {
      intervalRef.current && clearInterval(intervalRef.current);
      return;
    }
    setProgress(progress => progress + stepRef.current);
    stepRef.current = stepRef.current / 2;
  }, 1000);

  return (
    <div className={cn(styles.progressBar__container, className)}>
      <div className={styles.progressBar__progress} style={{ width: `${progress}%` }} />
      <span className={styles.progressBar__title}>{t('ServicePage:TestingForm:Computing')}</span>
    </div>
  );
};

export default React.memo(ProgressBar);
