import { useEffect } from 'react';

const DEFAULT_TITLE = 'Caila';

export function usePageTitle(title: string) {
  useEffect(() => {
    document.title = title;
    return () => {
      document.title = DEFAULT_TITLE;
    };
  }, [title]);
}

export default usePageTitle;
