import React, { useCallback, useMemo, useState } from 'react';
import { Icon } from '@just-ai/just-ui/dist/Icon';
import Button from 'components/Button';
import Spinner from 'components/Spinner';
import styles from './styles.module.scss';

const CARD_WIDTH = 389;
const GAP_WIDTH = 24;

interface CarouselInterface<ITEM extends object> {
  items?: ITEM[];
  itemPerSlide: number;
  renderItem: (item: ITEM, key: number) => React.ReactNode;
  title?: string;
  loading?: boolean;
}

const Carousel = <ITEM extends object>({
  items,
  renderItem,
  title,
  loading = false,
  itemPerSlide,
}: CarouselInterface<ITEM>) => {
  const slidersCount = useMemo(() => (!!items ? items?.length : 0), [items]);
  const [currentSlide, setCurrentSlide] = useState<number>(0);

  const handleMoveLeft = useCallback(() => {
    if (!slidersCount) return;
    setCurrentSlide(slideNumber => (slideNumber > 0 ? slideNumber - 1 : slideNumber));
  }, [slidersCount]);

  const handleMoveRight = useCallback(() => {
    if (!slidersCount) return;
    setCurrentSlide(slideNumber => (slideNumber < slidersCount - itemPerSlide ? slideNumber + 1 : slideNumber));
  }, [itemPerSlide, slidersCount]);

  if (!items?.length) return null;

  return (
    <div className={styles.carousel__wrapper} data-test-id='Carousel:Wrapper'>
      <Spinner show={loading} centered />
      <h2>{title}</h2>
      <div
        className={styles.carousel__container}
        data-test-id='Carousel:ItemsList'
        style={{ transform: `translateX(-${currentSlide * (CARD_WIDTH + GAP_WIDTH)}px)` }}
      >
        {items?.map((item, key) => renderItem(item, key))}
      </div>
      {slidersCount > itemPerSlide && (
        <div className={styles.carousel__buttonsContainer}>
          <Button
            data-test-id='Carousel:PreviousButton'
            className={styles.carousel__button}
            round
            disabled={currentSlide === 0}
            onClick={handleMoveLeft}
          >
            <Icon name='farChevronLeft' color='primary' />
          </Button>
          <Button
            data-test-id='Carousel:NextButton'
            className={styles.carousel__button}
            round
            disabled={currentSlide === slidersCount - itemPerSlide}
            onClick={handleMoveRight}
          >
            <Icon name='farChevronRight' color='primary' />
          </Button>
        </div>
      )}
    </div>
  );
};

export default Carousel;
