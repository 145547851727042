import React, { createContext, FunctionComponent, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Constructable, Container } from 'typedi';
import { Settings } from 'luxon';
import i18n from 'localization';
import Spinner from 'components/Spinner';
import MessageContainer, { notifyActionMessage } from 'components/MessageContainer';
import { parseError, getUserLanguage } from 'utils';
import { FrontendSettings, LanguageEnum } from 'api/CailagateApi/api/client';
import { register } from 'extendable-media-recorder';
import { connect } from 'extendable-media-recorder-wav-encoder';
import { SystemConfigApiService } from 'services/ApiServices/SystemConfigApiService';

export type AppContextType = {
  loading: boolean;
  setLoading: (loading: boolean) => void;
  diContainer: typeof Container;
  handleError: (error: any, useThrottle?: boolean) => void;
  changeLanguage: (language?: LanguageEnum) => Promise<void>;
  language: LanguageEnum;
  isBillingEnabled: boolean;
  isExtendedLanding: boolean;
};

const DEFAULT_LANGUAGE = LanguageEnum.RU;

export const AppContext = createContext({} as AppContextType);

export const AppContextProviderComponent: FunctionComponent = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [language, setLanguage] = useState<LanguageEnum>(DEFAULT_LANGUAGE);
  const errorSourcesRef = useRef<Set<string>>(new Set<string>());
  const systemConfigApi = Container.get(SystemConfigApiService);

  const [frontendSettings, setFrontendSettings] = useState<FrontendSettings | undefined>(undefined);

  const getFrontendSettings = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await systemConfigApi.getFrontendSettings();
      setFrontendSettings(data);
    } catch (error) {
      setFrontendSettings({ isBillingEnabled: false, isExtendedLanding: false });
    }
    setLoading(false);
  }, [systemConfigApi]);

  const handleError = useCallback((error: any, useThrottle = false) => {
    const errorMessage = parseError(error);
    if (!useThrottle) {
      notifyActionMessage(errorMessage, { type: 'error' });
    } else if (!errorSourcesRef.current.has(errorMessage)) {
      errorSourcesRef.current.add(errorMessage);
      setTimeout(() => errorSourcesRef.current.delete(errorMessage), 30000);
      notifyActionMessage(errorMessage, { type: 'error' });
    }
  }, []);

  const changeLanguage = useCallback(async (language?: LanguageEnum) => {
    const userLanguage = getUserLanguage(language);
    Settings.defaultLocale = userLanguage;
    await i18n.changeLanguage(userLanguage.toLowerCase());
    setLanguage(userLanguage);
    //TODO: axios headers language update
  }, []);

  const [isAppLaunching, setIsAppLaunching] = useState(true);

  async function init() {
    register(await connect());
  }

  useEffect(() => {
    Promise.allSettled([changeLanguage(DEFAULT_LANGUAGE), init(), getFrontendSettings()]).finally(() =>
      setIsAppLaunching(false)
    );
  }, [changeLanguage, getFrontendSettings]);

  if (isAppLaunching || !frontendSettings) return null;

  return (
    <AppContext.Provider
      value={{
        diContainer: Container,
        loading,
        language,
        setLoading: setLoading,
        handleError: handleError,
        changeLanguage: changeLanguage,
        isBillingEnabled: frontendSettings.isBillingEnabled,
        isExtendedLanding: frontendSettings.isExtendedLanding,
      }}
    >
      {children}
      <Spinner zIndex={1000} show={loading} />
      <MessageContainer />
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);
export const useDI = <CONTAINER_TYPE extends unknown>(type: Constructable<CONTAINER_TYPE>) =>
  useAppContext().diContainer.get(type);
