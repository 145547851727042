import React, { useCallback, useEffect, useState } from 'react';
import { history } from 'App';

import Button from 'components/Button';
import TrainingWidgetHeader from './components/TrainingWidgetHeader';
import TrainingForm, { useTrainingForm } from 'modules/ServiceFitComponents/components/TrainingForm';
import Spinner from 'components/Spinner';
import TrainingResult from '../components/TrainingResult';
import ProgressBar from 'components/ProgressBar';

import { useLoading } from 'utils/hooks';
import { useAuthContext } from 'contexts/AuthContext';
import { TrainingContextProviderComponent, useTrainingContext } from '../contexts/TrainingContext';
import { ModelInfoData } from 'api/CailagateApi/api/client';
import { WidgetComponentProps, withWidgetLayout } from 'HOC/withWidgetLayout';

import { useTrainingService } from '../services/TrainingService';
import {
  TrainingFormFieldsNamesList,
  formDefaulValues,
} from 'modules/ServiceFitComponents/components/TrainingForm/types';

import { WORKSPACE_BASE_PATH } from 'routes';
import { t } from 'localization';
import { handleFormErrors } from 'utils/form';

import styles from './styles.module.scss';

interface TrainingWidgetInterface {
  serviceData?: ModelInfoData;
}

function TrainingWidget({ isFullScreen }: WidgetComponentProps) {
  const [isLoading, , startLoading, endLoading] = useLoading();
  const { user } = useAuthContext();

  const { serviceData, getFormData, isLoadingGlobal } = useTrainingContext();

  const trainingService = useTrainingService();
  const [fitRequestResult, setFitRequestResult] = useState<ModelInfoData | undefined>();

  const formMethods = useTrainingForm({});

  const handleSubmit = async () => {
    if (!user || !serviceData?.id) return;
    const {
      id: { modelId, accountId: modelAccountId },
    } = serviceData;
    startLoading();
    formMethods.clearErrors('commonError');
    try {
      const result = await trainingService.submit(
        user.accountId.toString(),
        modelAccountId.toString(),
        modelId.toString(),
        formMethods.getValues()
      );
      formMethods.reset(formMethods.getValues());
      setFitRequestResult(result);
    } catch (error: any) {
      handleFormErrors(error, TrainingFormFieldsNamesList, formMethods.setError);
    }
    endLoading();
  };

  const handleReset = useCallback(() => {
    setFitRequestResult(undefined);
    formMethods.reset({ ...formDefaulValues });
    getFormData();
  }, [formMethods, getFormData]);

  useEffect(() => {
    if (formMethods.formState.isDirty && fitRequestResult) {
      setFitRequestResult(undefined);
    }
  }, [fitRequestResult, formMethods.formState.isDirty]);

  const content = (
    <>
      <Spinner show={isLoadingGlobal} />
      <TrainingWidgetHeader />
      <TrainingForm formMethods={formMethods} />
      {isLoading ? (
        <ProgressBar className={styles[`trainingWidget__submitButton${isFullScreen ? '-fullScreen' : ''}`]} />
      ) : (
        <Button
          color='primary'
          type='submit'
          className={styles[`trainingWidget__submitButton${isFullScreen ? '-fullScreen' : ''}`]}
          onClick={handleSubmit}
          data-test-id='trainingWidgetRunButton'
          disabled={!!fitRequestResult}
        >
          {t('TrainingWidget:RunFit')}
        </Button>
      )}
      <TrainingResult
        isFullScreen={isFullScreen}
        reset={handleReset}
        redirect={() => {
          fitRequestResult &&
            history.push(
              `/${WORKSPACE_BASE_PATH}/model/${fitRequestResult.id.accountId}/${fitRequestResult.id.modelId}`
            );
        }}
        fitIsRequested={!!fitRequestResult}
      />
    </>
  );

  return content;
}

const TrainingWidgetComponent = withWidgetLayout(TrainingWidget);

export default React.memo(({ serviceData }: TrainingWidgetInterface) => {
  if (!serviceData) return null;
  return (
    <TrainingContextProviderComponent serviceData={serviceData}>
      <TrainingWidgetComponent />
    </TrainingContextProviderComponent>
  );
});
