import React, { ComponentType } from 'react';
import { Route, RouteProps, Redirect } from 'react-router';
import { useAuthContext } from '../../contexts/AuthContext';

interface PrivateRouteInterface extends RouteProps {
  component?: ComponentType<RouteProps>;
  [key: string]: any;
}

const PrivateRoute = ({ component: Component, children, ...rest }: PrivateRouteInterface) => {
  const { isAuthenticated } = useAuthContext();
  // const renderComponent = (props: any) => (Component ? <Component {...props} /> : children);
  return (
    <Route
      {...rest}
      render={props => {
        if (!isAuthenticated) {
          return (
            <Redirect
              to={{
                pathname: '/',
                state: { from: props.location },
              }}
            />
          );
        } else {
          return children;
        }
      }}
    />
  );
};

export default PrivateRoute;
