import React, { useRef, useState } from 'react';
import { JustSelect, JustSelectProps, OptionType } from '@just-ai/just-ui';
import cn from 'classnames';
import styles from './styles.module.scss';
import useLockScroll from 'utils/hooks/useLockScroll';

export interface SelectInterface extends JustSelectProps {}
export interface SelectOptionType extends OptionType {}

const Select = ({ className, ...props }: SelectInterface, externalRef: React.Ref<JustSelect>) => {
  const [isOpened, setIsOpened] = useState(false);
  const childRef = useRef<HTMLDivElement>();
  useLockScroll(childRef.current, isOpened);

  return (
    <JustSelect
      className={cn(styles.select, className)}
      getRef={ref => {
        childRef.current = ref;
      }}
      onOpen={setIsOpened}
      ref={externalRef}
      {...props}
    />
  );
};

export default React.memo(React.forwardRef(Select));
