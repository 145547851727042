import React, { createContext, useContext } from 'react';
import Cookies from 'universal-cookie';

import { AppContext, AppContextType } from 'contexts/AppContext';
import { AccountApiService } from 'services/AccountApiService';
import { ModelApiService } from 'services/ApiServices/ModelApiService';

import { AccountDataDto, UserRoles } from './types';
import { AccountInfoData } from 'api/CailagateApi/api/client';
import {
  accounsadminAuthentication,
  checkIfUserIsSAdmin,
  getAllowedAccounts,
  getLoginLink,
  getRegisterLink,
  login,
  loginWithAccountPageRedirect,
  redirectToConversationCloud,
  register,
} from './utils';

const ONLY_MY = true;

export type AuthContextType = {
  user?: AccountInfoData;
  userMail?: string;
  userFeatures: string[] | undefined;
  userPermissions: string[] | undefined;
  userHasFeature: (feature?: string) => boolean;
  userHasPermission: (permission?: string) => boolean;
  updateUserInfoData: (userInfoData: AccountInfoData) => Promise<void>;
  isAuthenticated: boolean;
  isUserSuperadmin: boolean;
  userHasServices: boolean;
  userRole: UserRoles;
  login: (redirectPathname?: string) => void;
  loginWithAccountPageRedirect: () => void;
  logout: (redirectPathname?: string) => void;
  register: (redirectPathname?: string) => void;
  getRegisterLink: (redirectPathname?: string) => string;
  getLoginLink: (redirectPathname?: string) => string;
  changeAccount: (accountId: number) => Promise<void>;
  allowedAccounts: AccountDataDto[] | undefined;
};

export const AuthContext = createContext({} as AuthContextType);

interface AuthContextProviderProps {}

class AuthContextProviderState {
  isInitialized: boolean = false;
  userHasServices: boolean = false;
  isUserSuperadmin: boolean = false;
  user?: AccountInfoData = undefined;
  userMail?: string = undefined;
  userFeatures?: string[] = undefined;
  userPermissions?: string[] = undefined;
  allowedAccounts?: AccountDataDto[] = undefined;
}

const cookies = new Cookies();

export default class AuthContextProvider extends React.Component<AuthContextProviderProps, AuthContextProviderState> {
  static contextType = AppContext;
  context!: AppContextType;
  state = new AuthContextProviderState();

  componentDidMount() {
    this.checkUserAuth();
  }

  componentDidUpdate(prevProps: Readonly<AuthContextProviderProps>, prevState: Readonly<AuthContextProviderState>) {
    if (prevState.user?.language !== this.state.user?.language) {
      this.context.changeLanguage(this.state.user?.language);
    }
  }

  logout = () => {
    this.setState(prevState => ({ ...prevState, user: undefined }));
    redirectToConversationCloud(`/c/logout?redirectUrl=${window.location.origin}`);
  };

  checkUserAuth = async () => {
    let accounsadminUserInfo: any = undefined;
    let allowedAccounts: AccountDataDto[] = [];
    let userFeatures: string[] | undefined;
    let userMail: string | undefined;
    let userPermissions: string[] | undefined;
    let isUserSuperadmin: boolean = false;
    let currentUser: AccountInfoData | undefined = undefined;
    let userHasServices: boolean = false;
    this.context.setLoading(true);
    this.setState({
      isInitialized: false,
      userHasServices: false,
      user: undefined,
      userFeatures,
      userPermissions,
      isUserSuperadmin,
      allowedAccounts,
    });
    try {
      accounsadminUserInfo = await accounsadminAuthentication();
      userFeatures = accounsadminUserInfo?.userData?.features as string[] | undefined;
      userPermissions = accounsadminUserInfo?.userData?.permissions as string[] | undefined;
      userMail = accounsadminUserInfo?.userData?.login as string | undefined;
      const accountId = accounsadminUserInfo?.userData?.accountId as number | undefined;
      isUserSuperadmin = checkIfUserIsSAdmin(userPermissions, accountId);
    } catch (error) {
      console.error(error);
    }

    try {
      allowedAccounts = await getAllowedAccounts(accounsadminUserInfo?.userData?.userId);
      const defaultAccount = allowedAccounts.find((account: AccountDataDto) => account.default);
      if (!defaultAccount && !accounsadminUserInfo?.userData?.accountId && !accounsadminUserInfo?.userData?.internal) {
        return (window.location.href = '/c/select-project-group');
      }
    } catch (error) {
      console.error(error);
    }

    try {
      if (!isUserSuperadmin) {
        const accountApiService = this.context.diContainer.get(AccountApiService);
        const payload = await accountApiService.ensureAccount();
        currentUser = payload.data;
      }
    } catch (error) {
      console.error(error);
    }

    try {
      if (!!currentUser) {
        const modelApiService = this.context.diContainer.get(ModelApiService);
        const {
          data: { records: userServices },
        } = await modelApiService.getPagedModels(currentUser.accountId.toString(), ONLY_MY);
        userHasServices = userServices.length > 0;
      }
    } catch (error) {
      console.error(error);
    }

    this.setState({
      isInitialized: true,
      user: currentUser,
      userFeatures,
      userPermissions,
      isUserSuperadmin,
      allowedAccounts,
      userHasServices,
      userMail,
    });
    this.context.setLoading(false);
  };

  userHasFeature = (feature?: string) => {
    if (!feature) return false;
    feature = feature.toLowerCase();
    return !!this.state.userFeatures?.some(userFeature => userFeature.toLowerCase() === feature);
  };

  userHasPermission = (permission?: string) => {
    if (!permission) return false;
    permission = permission.toLowerCase();
    return !!this.state.userPermissions?.some(userPermission => userPermission.toLowerCase() === permission);
  };

  updateUserInfoData = async (userInfoData: AccountInfoData) => {
    const accountApiService = this.context.diContainer.get(AccountApiService);
    const { data } = await accountApiService.updateAccountData(userInfoData);
    this.setState({ user: data });
  };

  changeAccount = async (accountId: number) => {
    cookies.set('SELECTED_ACCOUNT', String(accountId), { path: '/' });
    this.checkUserAuth();
  };

  render() {
    const {
      user,
      userMail,
      userFeatures,
      userPermissions,
      userHasServices,
      isInitialized,
      isUserSuperadmin,
      allowedAccounts,
    } = this.state;
    if (!isInitialized) return null;
    const isAuthenticated = !!user || isUserSuperadmin;
    const userRole = isUserSuperadmin ? 'superadmin' : 'user';

    return (
      <AuthContext.Provider
        value={{
          user,
          userMail,
          userFeatures,
          userPermissions,
          userHasFeature: this.userHasFeature,
          userHasPermission: this.userHasPermission,
          updateUserInfoData: this.updateUserInfoData,
          isAuthenticated,
          isUserSuperadmin,
          userHasServices,
          userRole,
          login,
          loginWithAccountPageRedirect,
          logout: this.logout,
          register,
          getRegisterLink,
          getLoginLink,
          changeAccount: this.changeAccount,
          allowedAccounts: allowedAccounts,
        }}
      >
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}

export const useAuthContext = () => useContext(AuthContext);
