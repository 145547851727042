import { ModelInfoData } from 'api/CailagateApi/api/client';
import TrainingWidget from '../../../ServiceFitComponents/TrainingWidget';
import ChatTestWidget from '../../../ServiceTestComponents/ChatTestWidget';
import RecordTestWidget from 'modules/ServiceTestComponents/RecordTestWidget';
import Tester from 'modules/ServiceTestComponents/Tester';
import ImageTestWidget from 'modules/ServiceTestComponents/ImageTestWidget';
import { ServiceTaskTypes } from 'contexts/ServicesContext/types';

export const getWidgetComponent = (
  fittable: boolean,
  serviceTaskType?: ServiceTaskTypes
): React.FC<{ serviceData: ModelInfoData }> => {
  if (fittable) {
    return TrainingWidget;
  }
  switch (serviceTaskType) {
    case 'chat': {
      return ChatTestWidget;
    }
    case 'asr': {
      return RecordTestWidget;
    }
    case 'image-generation': {
      return ImageTestWidget;
    }
    default: {
      return Tester;
    }
  }
};
